import React from "react";
import "../../Assets/css/case.css";
import "../../Assets/css/base.css";
import "../../Assets/css/demo1.css";
import "../../Assets/css/home.css";
import ReactPlayer from "react-player";
import Parallax from "react-rellax";
import $ from "jquery";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Header from "../../components/header";
import Footer from "../../components/footer";
import SEO from "../../components/seo";

class IndexPage extends React.Component {
  componentDidMount() {
    require("paroller.js");
    $(document).ready(function() {
      $(".paroller-example").paroller({
        factor: 0.15,
        type: "foreground",
        direction: "vertical"
      });
    });
  }

  render() {
    return (
      <div>
        <Header></Header>
        <SEO
          title="Driha Apps · A new take on the common type of apps ( to-do apps )"
          description="Project management, web editorial, advanced SEO and
                    Net-linking on a single platform"
          image={
            "https://makook.space" +
            require("../../images/Dirha/Dirha-cover.jpg")
          }
        />

        <div
          data-post-title="makookcase"
          data-theme="theme-dark"
          className="theme-dark"
        >
          <main className="case makookcase">
            <h1 className="master-title">Dirha a To-do app</h1>

            <div
              className="background-color-makookcase-1 color-transition"
              data-color="#000"
            >
              <section className="image-block sticky-title-padding-top mobile-hero no-margin-bottom sticky-stop">
                <figure>
                  <Img fluid={this.props.data.imageOne.childImageSharp.fluid} />
                  <Parallax speed={-2} percentage={0.5} centered>
                    <p className=" desktop-only">
                      A new take on the common type of apps ( to-do apps )
                    </p>
                  </Parallax>

                  <Parallax speed={-2} percentage={0.5} centered>
                    <p className="mobile-only">
                      A new take on the common type of apps ( to-do apps )
                    </p>
                  </Parallax>
                </figure>
              </section>

              <section className="image-text push-up half-padding-bottom no-margin-bottom">
                <figure>
                  <Img fluid={this.props.data.imageTwo.childImageSharp.fluid} />
                </figure>
                <article>
                  <span>Date</span>
                  <p>2016 – 2018</p>
                  <span>Challenge</span>
                  <p>
                    There are countless todo apps on the App Store ranging from
                    Big-Tech companies creations, to many small developers with
                    their own take on the matter. Many of these apps oversee the
                    purpose of a good to-do solution which is "to get things
                    done in the most simple, easy, fast way possible). Most of
                    these apps have a lot of options and features, in many cases
                    options equals confusion, in this confusion the users find
                    them selves writing detailed to-do lists rather than doing
                    them. Therefor plenty of apps become obsolete after just
                    weeks of use.
                    <br></br>
                    The goal is how to make an app that really helps a user get
                    things done in the most intuitive way possible.
                  </p>
                  <span>Solution</span>
                  <p>
                    With a clear goal in mind, we taped first into the vast sea
                    of to-do apps available on the appStores and playStore of
                    the world, Benchmarking the the usability and features of
                    the most popular ones. On this foundation we set off
                    ideating our findings on functional prototypes basing our
                    solution on real world scenarios.
                    <br></br>
                    We found that designing for a seamless user experience is
                    key to the ease of use of our product, grounding our
                    aesthetics on minimalism which communicates the app visuals
                    through two primary colours taking in count the colourblind,
                    But most importantly translate the importance of a task via
                    "TEXT only". All the above wouldn't be possible without a
                    solid base that is a purposeful design system which was
                    op­ti­mised for max­imum mod­ul­arity, scalab­il­ity and
                    usa­bil­ity.
                  </p>
                  <span>Disciplines</span>
                  <p>
                    Brand Identity
                    <br />
                    Design System
                    <br />
                    User Experience
                    <br />
                    User Research
                    <br />
                    Prototyping
                    <br />
                    System Development
                    <br />
                    API Development
                    <br />
                    System Integration
                  </p>
                </article>
              </section>

              <section className="mobile-text mobile-only">
                <article>
                  <div>
                    <span>Date</span>
                    <p>2016 – 2018</p>
                  </div>
                  <div>
                    <span>Challenge</span>
                    <p>
                      There are countless todo apps on the App Store ranging
                      from Big-Tech companies creations, to many small
                      developers with their own take on the matter. Many of
                      these apps oversee the purpose of a good to-do solution
                      which is "to get things done in the most simple, easy,
                      fast way possible). Most of these apps have a lot of
                      options and features, in many cases options equals
                      confusion, in this confusion the users find them selves
                      writing detailed to-do lists rather than doing them.
                      Therefor plenty of apps become obsolete after just weeks
                      of use.
                      <br></br>
                      The goal is how to make an app that really helps a user
                      get things done in the most intuitive way possible.
                    </p>
                  </div>
                  <div>
                    <span>Solution</span>
                    <p>
                      With a clear goal in mind, we taped first into the vast
                      sea of to-do apps available on the appStores and playStore
                      of the world, Benchmarking the the usability and features
                      of the most popular ones. On this foundation we set off
                      ideating our findings on functional prototypes basing our
                      solution on real world scenarios.
                      <br></br>
                      We found that designing for a seamless user experience is
                      key to the ease of use of our product, grounding our
                      aesthetics on minimalism which communicates the app
                      visuals through two primary colours taking in count the
                      colourblind, But most importantly translate the importance
                      of a task via "TEXT only". All the above wouldn't be
                      possible without a solid base that is a purposeful design
                      system which was op­ti­mised for max­imum mod­ul­arity,
                      scalab­il­ity and usa­bil­ity.
                    </p>
                  </div>
                  <div>
                    <span>Disciplines</span>
                    <p>
                      Brand Identity
                      <br />
                      Design System
                      <br />
                      User Experience
                      <br />
                      User Research
                      <br />
                      Prototyping
                      <br />
                      System Development
                      <br />
                      API Development
                      <br />
                      System Integration
                    </p>
                  </div>
                </article>
              </section>
            </div>

            <div
              className="background-color-makookcase-2 color-transition"
              data-color="#1b1b1b"
            >
              <section className="image-block half-margin-bottom">
                <figure>
                  <Img
                    fluid={this.props.data.imageThree.childImageSharp.fluid}
                  />
                </figure>
              </section>

              <section className="image-block half-margin-bottom">
                <figure>
                  <Img
                    fluid={this.props.data.imageFour.childImageSharp.fluid}
                  />
                </figure>
              </section>

              <section className="makookcase-block-1">
                <h3 className="paroller-example paroller-title desktop-only">
                  A streamlined user experience across platforms with
                  persona-based interfaces and behaviour-changing functionality.
                </h3>
              </section>

              <section className="image-block">
                <figure className="">
                  <Img
                    fluid={this.props.data.imageFive.childImageSharp.fluid}
                  />
                </figure>
              </section>

              <section className="image-text half-margin-bottom">
                <figure>
                  <Img fluid={this.props.data.imageSix.childImageSharp.fluid} />

                  <Parallax speed={2}>
                    <h3 className=" mobile-only">
                      An honest and purposeful design system that allows users
                      to focus on what’s most important — getting things done.
                    </h3>
                  </Parallax>
                </figure>
                <article>
                  <Parallax speed={-2} percentage={0.5} centered>
                    <h3 className=" desktop-only" data-func="caseIntroParallax">
                      An honest and purposeful design system that allows users
                      to focus on what’s most important — getting things done.
                    </h3>
                  </Parallax>
                </article>
              </section>

              <section className="image-block">
                <figure className="">
                  <ReactPlayer
                    className=""
                    url={require("../../images/makook-dirha-darkmode.mp4")}
                    width="100%"
                    height="100%"
                    playing
                    muted
                    loop
                  />
                </figure>
              </section>

              <Footer />
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default IndexPage;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1400, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "Dirha/Dirha-cover.jpg" }) {
      ...fluidImage
    }

    imageTwo: file(relativePath: { eq: "Dirha/Dirha-branding.jpg" }) {
      ...fluidImage
    }

    imageThree: file(relativePath: { eq: "Dirha/Dirha-3-screens.jpg" }) {
      ...fluidImage
    }

    imageFour: file(relativePath: { eq: "Dirha/caleder-menu.jpg" }) {
      ...fluidImage
    }

    imageFive: file(relativePath: { eq: "Dirha/ipad-pro.jpg" }) {
      ...fluidImage
    }

    imageSix: file(relativePath: { eq: "Dirha/dirha-dark-mode.png" }) {
      ...fluidImage
    }
  }
`;
